<template>
  <div class="flex flex-1 flex-col min-h-full max-h-full overflow-hidden">
    <sub-header :backButton="true" />
    <div
      class="flex flex-1 gap-4 flex-grow max-h-full max-w-full overflow-auto p-6"
    >
      <div
        class="bg-white min-h-full overflow-hidden rounded-md shadow-md"
        :class="currentField && currentField.has_options ? 'w-6/12' : 'flex-1'"
      >
        <div class="flex items-center justify-center flex-1" v-if="loading">
          <loader class="my-5" :size="8" />
        </div>
        <div
          v-if="fieldData && !loading"
          class="
            bg-white
            scroll-bar
            flex flex-1 flex-col
            gap-5
            min-h-full
            max-h-full
            overflow-auto
            p-6
            rounded-sm
          "
        >
          <div class="flex gap-3 items-center justify-between">
            <div class="flex gap-3 items-center">
              <div
                class="
                  flex
                  gap-1
                  items-center
                  justify-center
                  flex-wrap flex-col
                  rounded-lg
                  shadow-md
                  bg-gray-100
                "
                style="
                  max-height: 45px;
                  max-width: 45px;
                  min-height: 45px;
                  min-width: 45px;
                "
              >
                <img
                  :src="fieldTypeIcon"
                  class="h-8 w-8 opacity-70"
                  style="height: 20px; width: 20px"
                />
                <!-- <span class="text-xs">{{fieldData.field_type}}</span> -->
              </div>
              <h3 class="m-0">
                Enter Details for field type
                <span
                  class="badge rounded capitalize badge-info text-base py-1"
                >
                  {{ fieldData.type }}</span
                >
              </h3>
            </div>
            <button
              v-if="showDeleteIcon"
              @click="handleDeleteField(fieldData)"
              class="flex btn btn-outline btn-error btn-circle"
            >
              <font-awesome-icon icon="trash" />
            </button>
          </div>
          <div class="min-h-full">
              <FormulateForm #default="{ hasErrors }" class="min-h-full flex-1">
                  <div class="flex flex-col justify-between min-h-full">
                    <div class="form flex w-full  flex-col flex-1">
                        <FormulateInput
                          class="w-full"
                          label="Field Label "
                          placeholder="Field Label "
                          type="text"
                          validation="required"
                          :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                          v-model="fieldData.label"
                        />
                        <FormulateInput
                          class="w-full"
                          label="Field Name"
                          placeholder="Field Name"
                          type="text"
                          validation="required"
                          :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                          v-model="fieldData.name"
                        />
                        <FormulateInput
                          class="w-full"
                          label="Placeholder"
                          placeholder="Placeholder"
                          type="text"
                          :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                          v-model="fieldData.placeholder"
                        />
                        <FormulateInput
                          class="w-full"
                          label="Description"
                          :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                          placeholder="Description"
                          type="textarea"
                          validation="required"
                          v-model="fieldData.description"
                        />
                    </div>
                    <div class="card-actions justify-end">
                      <Button
                        text="Save"
                        type="primary"
                        :disabled="hasErrors"
                        @click="handleSaveAndCreateField()"
                      />
                    </div>
                  </div>
              </FormulateForm>

          </div>
        </div>
        
      </div>
      <div
        class="w-6/12 shadow-md bg-white rounded-md p-3 flex-1"
        v-if="currentField && currentField.has_options"
      >
        <div
          class="flex items-center h-full justify-center flex-1"
          v-if="optionsLoading"
        >
          <loader class="my-5" size="8" />
        </div>
        <div v-else class="flex flex-col max-h-full">
          <div class="flex gap-3 mb-4 mt-2 h-full items-center justify-between">
            <span class="text-gray-500 text-lg font-semibold"> Options</span>
            <button
              v-if="!fieldOptions.length"
              @click="handleAddOptions(0)"
              class="flex btn btn-info text-xs btn-md"
            >
              <font-awesome-icon icon="plus" class="text-md mr-3" />
              Add Options
            </button>
          </div>
          <div
            class="
              options__list
              flex flex-col
              gap-2
              scroll-bar
              h-full
              py-4
              max-h-full
              overflow-y-auto overflow-x-hidden
            "
          >
            <div
              class="
                border border-gray-100
                flex flex-col
                px-3
                pt-8
                pb-4
                relative
              "
              v-for="(option, index) in fieldOptions"
              :key="`option-${index}`"
            >
              <div
                class="flex gap-2 flex-row max-w-full mb-3 rounded items-center"
              >
                <neo-input
                  placeholder="Option Label"
                  v-model="option.label"
                  :disabled="
                    !showCascadedField && !option.edited && !option.added
                  "
                  class="rounded flex-grow"
                  :error="
                    option &&
                    option.errors &&
                    option.errors.label &&
                    !option.label
                  "
                >
                </neo-input>

                <neo-input
                  placeholder="Option Value"
                  bg="white"
                  :disabled="
                    !showCascadedField && !option.edited && !option.added
                  "
                  v-model="option.value"
                  :showAppendIcon="true"
                  appendIcon="copy"
                  class="rounded flex-grow"
                  :error="
                    option &&
                    option.errors &&
                    option.errors.value &&
                    !option.value
                  "
                  @appendHandleEvent="appendHandleEvent(index)"
                >
                </neo-input>
                <div
                  class="btn-group flex items-stretch flex-nowrap self-center"
                >
                  <button
                    class="btn btn-sm btn-info"
                    :disabled="
                      index !== fieldOptions.length - 1 && !fieldIsCascaded
                    "
                    @click="handleAddOptions(index)"
                  >
                    <font-awesome-icon icon="plus" class="text-white-text" />
                  </button>

                  <button
                    class="btn btn-sm"
                    v-if="!option.added"
                    @click="handleEditOption(index)"
                    :class="!option.edited ? 'btn-info' : 'btn-success'"
                  >
                    <font-awesome-icon icon="edit" />
                  </button>

                  <button
                    v-if="!option.added"
                    class="btn btn-sm btn-info relative"
                    @click="showDependentFields(index)"
                  >
                    <!-- v-popover.left="{ name: `${index}-options-${tippyName}` }" -->
                    <font-awesome-icon
                      icon="link"
                      :class="
                        option && option.dependents && option.dependents.length
                          ? 'text-white-text'
                          : 'text-gray-200'
                      "
                    />
                    <span
                      v-if="
                        option && option.dependents && option.dependents.length
                      "
                      class="
                        p-0.5
                        w-4
                        h-4
                        text-center
                        rounded-half
                        text-white-text text-xs
                        font-semibold
                        absolute
                        bg-dvbrandhoveron
                      "
                      style="top: -8px; right: -5px"
                    >
                      {{ option.dependents.length }}
                    </span>
                  </button>
                  <button
                    class="btn btn-sm btn-error"
                    @click="handleDeleteOptions(option, fieldData)"
                  >
                    <font-awesome-icon icon="trash" />
                  </button>
                </div>

                <!-- <popover
                  :name="`${index}-options-${tippyName}`"
                  :width="240"
                  class="flex flex-wrap h-auto max-w-28 p-4 rounded-md"
                >
                  
                </popover> -->
              </div>
              <button
                class="btn absolute top-0 -right-0 text-xs rounded-box btn-sm"
                v-if="
                  (option.edited || option.showDependentFields) &&
                  option.createdDefault
                "
                style="background: #6b6; border-color: #6b6"
                @click="handleOptionUpdate(index)"
              >
                Save
              </button>
            </div>
            <loader-full
              v-if="isEditLoading"
              loadingText="Fields and Options Updating..."
            />

            <!-- <alert
              v-if="fieldIsCascaded"
              type="error"
              class="flex-col mt-5"
              message="You can't add or edit cascaded fields"
            /> -->
            <!-- <div class="mt-4 flex justify-end max-w-full gap-4">
              <button
              @click="handleSaveOptions()"
              class="flex btn btn-info text-xs"
            >
              <font-awesome-icon icon="plus" class="text-md mr-3" />
              Save Options
            </button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <modal-confirm
      title="Are you sure?"
      message="Please confirm you're about to delete the field."
      ref="confirm-popup"
    ></modal-confirm>

    <modal-content
      ref="depModal"
      :max-width="480"
      name="dependents"
      :showFooter="false"
      title="Field Dependents"
      @close="closeDependentFields"
    >
      <template #content>
        <div class="flex-1">
          <div class="flex flex-col mb-4">
            <span class="text-gray-6 inline-block py-2"
              >Select Dependent Fields</span
            >
            <vue-multiselect
              :multiple="true"
              :limit="3"
              :checkboxes="true"
              :taggable="false"
              :close-on-select="false"
              :clear-on-select="true"
              :preserve-search="true"
              placeholder="select all"
              :show-labels="false"
              :maxHeight="200"
              :options="fieldLists"
              selectLabel=""
              track-by="id"
              label="name"
              v-model="popup.dependents"
            >
            </vue-multiselect>
          </div>
        </div>
        <div
          class="
            flex-1 flex flex-col
            border border-dashed
            min-h-16
            border-gray-200
            p-2
          "
        >
          <span class="font-medium">Dependent Fields</span>
          <span
            class="text-gray-600"
            v-for="dep in popup.dependents"
            :key="dep.id"
          >
            {{ dep.name }}
          </span>
        </div>
      </template>
      
    </modal-content>
  </div>
</template>
<script>
import SubHeader from "@/components/SubHeader";
import Input from "@/components/input";
import { uuid } from "vue-uuid";
import { FETCH_FIELDS } from "@/modules/fields-admin/store";
import axios from "@/axios";
import loader from "@/components/loader";
// import modalConfirm from "@/components/
const modalConfirm = () => import("@/components/modal-confirm");
import modalContent from "@/components/modal-content";
const Button = () => import("@/components/button");
import VueMultiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
// import alert from "@/components/alert-box";
import LoaderFull from "@/components/loader-full";

export default {
  name: "field-table-data-view",
  components: {
    SubHeader,
    "neo-input": Input,
    // tippy: TippyComponent,
    VueMultiselect,
    modalConfirm,
    loader,
    // alert,
    Button,
    modalContent,
    "loader-full": LoaderFull,
  },
  props: {},
  data: () => ({
    loading: false,
    fields: [],
    fieldLists: [],
    fieldData: {
      name: "",
      description: "",
      field_type: "",
      placeholder: "",
      label: "",
      is_cascaded: false,
      tenant_id: "",
      options: [],
    },
    fieldOptions: [],
    fieldTypeIcon: null,
    error: null,
    isLoading: false,
    showDeleteIcon: false,
    optionsLoading: false,
    errorCount: 0,
    showCascadedField: false,
    currentField: null,
    fieldIsCascaded: false,
    isEditLoading: false,
    popup: {
      dependents: [],
      showDependentFields: true,
    },
  }),

  computed: {
    // fieldOptions() {
    //   return this.fieldLists.map(el => ({id: el.id, name: el.name}))
    // }
  },
  //   created() {
  //     // watch the params of the route to fetch the data again
  //     this.$watch(
  //       () => this.$route.params,
  //       () => {
  //         // this.fetchData();
  //       },
  //       // fetch the data when the view is created and the data is
  //       // already being observed
  //       { immediate: true }
  //     );
  //   },
  async mounted() {
    if (!this.$store.getters.fieldsType.length) {
      await this.$store.dispatch(FETCH_FIELDS);
    }
    this.fields = this.$store.getters.fieldsType;
    if (!this.$store.getters.getTenantId)
      await this.$store.dispatch("fetchTenantId");
    this.fieldLists = await this.fetchFieldsList();
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      let field;
      this.currentField = null;
      let query = this.$route;
      this.loading = true;
      // edited
      if (query.params.id) {
        this.showDeleteIcon = true;
        this.error = this.fieldData = null;
        let field_data = await this.fetchFieldData(query.params.id);
        this.fieldIsCascaded = this.showCascadedField = field_data.is_cascaded;
        field = this.fields.find((ele) => ele.name === field_data.type);
        this.currentField = field;
        if (field && field.has_options) {
          this.optionsLoading = true;
          let options = await this.fetchFieldsOptions(field_data.id);
          this.fieldOptions = options.map((option) => {
            return {
              ...option,
              createdDefault: true,
              edited: false,
              showDependentField: false,
            };
          });
        }

        this.fieldData = field_data;
      }
      // added new
      else if (query.query.field) {
        this.showDeleteIcon = false;
        this.showCascadedField = true;
        field = this.fields.find((ele) => ele.id === query.query.field);
        this.currentField = field;
        this.fieldData = {
          ...this.fieldData,
          field_type: field.id,
          type: field.name,
          tenant_id: this.$store.getters.getTenantId,
          is_cascaded: field.has_options,
          added: true,
          not_saved: true,
        };
        if (field?.has_options) {
          let options = await this.fetchFieldsOptions(field.id);
          this.fieldOptions = options.map((option) => {
            return {
              ...option,
              createdDefault: true,
              edited: false,
            };
          });
          //   this.optionsLoading = false;
        }
      }
      this.fieldTypeIcon = this.getIcon(field.name);
      this.loading = false;
      this.optionsLoading = false;
    },
    async handleSaveAndCreateField() {
      let { name, label, description, placeholder } = this.fieldData;
      let query = this.$route;
      if (!name || !label || !description || !placeholder) {
        this.$toast.error("Please fill all mandatory fields");
      } else {
        if (query.query.field && query.name === "Add Field") {
          let { result } = await this.createField();
          if (result) {
            this.$router.push({ path: "/field-table" });
          }
        } else {
          this.isEditLoading = true;
          let { result } = await this.saveData();
          if (result) {
            this.$toast.success(result.data.message);
          }
          this.isEditLoading = false;
        }
      }
    },
    async fetchFieldsList() {
      this.isLoading = true;
      let payload = {
        tenant_id: this.$store.getters.getTenantId,
        req_offset: 0,
        req_limit: 1,
      };
      let url = `/fields/${this.$store.getters.getTenantId}/all`;
      let result = await axios.get(url, payload);
      this.isLoading = false;
      return result.data.fields;
    },
    async createField() {
      let query = this.$route;
      let field = this.fields.find((ele) => ele.id === query.query.field);
      let post_data = { ...this.fieldData, is_cascaded: false, options: [] };
      let error = false;
      if (field?.has_options) {
        let fieldoptions_data = this.fieldOptions;
        let FieldsOptionsList = [];
        fieldoptions_data.map((el) => {
          // let dependent_fields = el.dependent_fields;
          // let new_dependednt_fields = [];
          // dependent_fields.map((ele) =>
          //   new_dependednt_fields.push(`${ele.id}`)
          // );
          FieldsOptionsList.push({
            label: el.label,
            value: el.value,
            order: el.order,
            dependent_fields: el?.dependents
              ? el?.dependents?.map((fld) => fld.id)
              : [],
          });
        });

        let { errorCount, isCascaded } = this.ValidateOptions();
        // if (isCascaded.cascaded > 0 && isCascaded.notCascaded > 0) {
        //   error = true;
        //   return this.$toast.error("cascaded Fields required");
        // }
        if (errorCount > 0 || !FieldsOptionsList.length) {
          error = true;
          return this.$toast.error("Atleast one option is required");
        }
        post_data = {
          ...post_data,
          options: FieldsOptionsList,
          is_cascaded: isCascaded.cascaded > 0 ? true : false,
        };
      }
      let url = `/fields`;
      let result = await axios.post(url, post_data);
      return { result, error };
    },

    async fetchFieldData(fieldId) {
      let url = `/fields/${this.$store.getters.getTenantId}/field/${fieldId}`;
      let result = await axios.get(url);
      return result.data;
    },

    handleSaveOptions() {
      let { errorCount } = this.ValidateOptions();
      if (errorCount > 0) {
        this.$toast.error("please fill all mandatory fields");
      } else {
        console.log(this.fieldOptions, "Fields options");
      }
    },
    ValidateOptions() {
      this.errorCount = 0;
      let cascadedData = {
        cascaded: 0,
        notCascaded: 0,
      };
      let data = this.fieldOptions.map((el) => {
        let errors = {
          label: false,
          value: false,
        };
        if (el.dependent_fields && el.dependent_fields.length) {
          cascadedData.cascaded += 1;
        } else {
          cascadedData.notCascaded += 1;
        }
        if (!el.label.length) {
          errors.label = true;
          this.errorCount += 1;
        }
        if (!el.value.length) {
          errors.value = true;
          this.errorCount += 1;
        }
        console.log(errors, "Errors");
        return { ...el, errors: errors };
      });
      this.fieldOptions = data;
      return { errorCount: this.errorCount, isCascaded: cascadedData };
    },

    async handleAddOptions(index) {
      let fieldId = this.$route.params.id;
      let { errorCount } = this.ValidateOptions();
        if (errorCount > 0) {
          this.$toast.error("Please fill all mandatory fields");
        } else {
          let data = {
            // for dummy content onl
            id: Math.round(Math.random() * 1000000000),
            // id: fieldId,
            label: "",
            value: "",
            added: true,
            edited: false,
            order: this.fieldOptions.length + 1 || 1,
            dependent_fields: [],
          };
          if (index != 0 && fieldId) {
            const fieldOption = this.fieldOptions[index] ?? null;
            console.log("fieldOption :>> ", fieldOption);
            if (!fieldOption.createdDefault) {
              try{
                let {data} = await this.hanleAddOptionsApi(
                  fieldId,
                  fieldOption
                );
                console.log(data, "resposne")
                this.fieldOptions[index].added = false;
                this.fieldOptions[index].id = data.option_id;
                this.fieldOptions[index].createdDefault = true
                this.$toast.success(data.message);

              }catch(error){
                this.fieldOptions[index].added = true;
                this.fieldOptions[index].errors = {label: true, value:true} 
                this.fieldOptions[index].label = ""
                this.fieldOptions[index].value = ""
                return this.$toast.error(error.response.data.detail || "Error while Creating Options");
              }
            }
        }
        console.log(data, "Datatt")
        this.fieldOptions.push(data);
      }
    },

    async saveData() {
      let field_data = this.fieldData;
      let fieldId = this.$route.params.id;
      let post_data = {
        tenant_id: field_data.tenant_id,
        field_id: fieldId,
        label: field_data.label,
        name: field_data.name,
        description: field_data.description,
        placeholder: field_data.placeholder,
      };
      let field = this.fields.find((ele) => ele.name === field_data.type);
      let error = false;
      if (field.has_options) {
        let fieldoptions_data = this.fieldOptions;
        let FieldsOptionsList = [];
        fieldoptions_data.map((el) => {
          let dependent_fields = el.dependent_fields || [];
          let new_dependednt_fields = [];
          dependent_fields?.map((ele) =>
            new_dependednt_fields.push(`${ele.id}`)
          );
          FieldsOptionsList.push({
            id: el.id,
            label: el.label,
            value: el.value,
            edited: el.edited,
            added: el.added,
            dependent_fields: new_dependednt_fields,
          });
        });

        let { errorCount, isCascaded } = this.ValidateOptions();
        console.log(errorCount, "erro Count");
        console.log(isCascaded, "Is Cascaded");

        if (errorCount > 0 || !FieldsOptionsList.length) {
          error = true;
          return this.$toast.error("All fields are mandatory");
        } else if (!FieldsOptionsList.length) {
          error = true;
          return this.$toast.error("Atleast one option is required");
        }

        FieldsOptionsList.map(async (optionData) => {
          if (optionData.edited) {
            try {
              let response = await this.hanleUpdateOptionsApi(
                fieldId,
                optionData
              );
              this.$toast.success(response.data.message);
              this.isEditLoading = false;
            } catch (err) {
              this.$toast.error(err.response.data.detail);
              this.isEditLoading = false;
            }
          }
          if (optionData.added) {
            try {
              let response = await this.hanleAddOptionsApi(fieldId, optionData);
              this.$toast.success(response.data.message);
              this.isEditLoading = false;
            } catch (err) {
              this.$toast.error(err.response.data.detail);
              this.isEditLoading = false;
            }
          }
        });
      }
      let url = `/fields`;
      let result = await axios.put(url, post_data);
      return { result, error };
    },

    async hanleUpdateOptionsApi(field_id, option) {
      let url = `/fields/${field_id}/options/${option.id}`;
      let orderList = []
      this.fieldOptions.map((el) => {
          console.log(el, "Element")
          if(el.createdDefault){
            let obj = {};
            obj[el.id] = el.order;
            orderList.push(obj)
          }
        })
      let post_data = {
        option_value: option.value,
        option_label: option.label,
        is_default: false,
        order: option.order,
        order_list: orderList
      };
      let result = await axios.put(url, post_data);
      return result;
    },

    async hanleAddOptionsApi(field_id, option) {
      let url = `/fields/${field_id}/options`;
      let post_data = {
        option_value: option.value,
        option_label: option.label,
        is_default: true,
        order: option.order,
        dependent_field_id: option.dependents || [],
      };
      let result = await axios.post(url, post_data);
      let fieloptionsList = this.fieldOptions.map((el) => {
        if (el.id === option.id) {
          return { ...el, added: false };
        } else {
          return { ...el };
        }
      });
      this.fieldOptions = fieloptionsList;
      this.$forceUpdate();
      console.log(this.fieldOptions, "foel");
      return result;
    },
    async handleOptionUpdate(index) {
      let option = this.fieldOptions[index];
      if (!option.label && !option.value) {
        this.$toast.error("please fill mandatory fields");
      } else {
        const resp = await this.hanleUpdateOptionsApi(
          this.$route.params.id,
          option
        );
        this.fieldOptions[index].edited = false;
        this.$toast.success(resp.data.message ?? "Options updated!");
      }
    },

    appendHandleEvent(index) {
      let labelvalue = this.fieldOptions[index].label;
      return (this.fieldOptions[index].value = labelvalue);
    },
    async handleEditOption(index) {
      this.fieldOptions[index].edited = !this.fieldOptions[index].edited;
    },
    showDependentFields(index) {
      this.$refs["depModal"].showModal();

      let field = this.fieldOptions[index];
      console.log("field :>> ", field);
      this.popup.dependents = field.dependents ?? [];
      this.popup.index = index;
      // field.showDependentFields = !field.showDependentFields;
      // // this.fieldOptions[index].showDependentFields = !this.fieldOptions[index].showDependentFields;
      // this.fieldOptions = this.fieldOptions.map((fld, idx) =>
      //   idx === index ? field : fld
      // );
    },
    async closeDependentFields() {
      let index = this.popup.index;
      let field = this.fieldOptions[index];
      field.dependents = this.popup.dependents;
      // this.fieldOptions[index].showDependentFields = !this.fieldOptions[index].showDependentFields;
      this.fieldOptions = this.fieldOptions.map((fld, idx) =>
        index === idx ? field : fld
      );
      this.popup = { dependents: [], index: null };
      this.$refs["depModal"].hideModal();
      !this.fieldData.not_saved && (await this.updateDependents(field));
      this.$forceUpdate();
    },
    async updateDependents(option) {
      try {
        let field_id = this.$route.params.id;
        const response = await axios.put(
          `/fields/${field_id}/options/${option.id}/dependents`,
          {
            dependents: option.dependents.map((el) => el.id),
          }
        );
        if (response && response.data) {
          this.$toast.success(response.data.message);
        }
      } catch (error) {
        this.$toast.error(error.message || "Cant update depdendents");
        console.log("error :>> ", error);
      }
    },
    async handleDeleteOptions(option, field_data) {
      const promise = await this.$refs["confirm-popup"].show({
        title: "Are you sure?",
        message:
          "This field option  will be deleted permanently. Please confirm to continue deleting this field option.",
      });

      if (promise) {
        let option_id = option.id;
        let fieldId = field_data.id;
        let created_default_length = 0
        this.fieldOptions.map(el => {
          if(el.createdDefault){
            created_default_length +=1
          }
        })
        if (created_default_length < 2 && !option.added) {
          this.$refs["confirm-popup"].close();
          return this.$toast.error("Atleast one option is required");
        }
        if (option.createdDefault) {
          let url = `/fields/${fieldId}/options/${option_id}`;
          try {
            let result = await axios.delete(url);
            this.$toast.success(result.data.message);
            if (result) {
              let optionsData = this.fieldOptions.filter(
                (el) => el.id !== option_id
              );
              this.$refs["confirm-popup"].close();
              return (this.fieldOptions = optionsData);
            }
          } catch (error) {
            this.$refs["confirm-popup"].close();
            this.$toast.error(error.response.data.detail);
          }
        } else {
          let optionsData = this.fieldOptions.filter(
            (el) => el.id !== option_id
          );
          this.$refs["confirm-popup"].close();
          return (this.fieldOptions = optionsData);
        }
      }
      this.$refs["confirm-popup"].close();
    },
    tippyName() {
      return `${uuid.v4()}`;
    },
    getIcon(field_name) {
      return this.fields.find((ele) => ele.name === field_name)?.icon || "";
    },
    async fetchFieldsOptions(fieldId) {
      let result = await axios.get(`/fields/${fieldId}/options`);
      let formatedResult = [];
      let dependent_fields = (dep_fel) => {
        return dep_fel.map((el) => {
          let val = Object.keys(el)[0];
          return {
            id: val,
            name: el[val],
          };
        });
      };
      result.data.options.map((ele, index) => {
        formatedResult.push({
          ...ele,
          id: ele.id,
          label: ele.option_label,
          value: ele.option_value,
          order: index + 1,
          dependents: dependent_fields(ele.dependents),
        });
      });
      return formatedResult;
    },

    async handleDeleteField(field) {
      const promise = await this.$refs["confirm-popup"].show({
        title: "Are you sure?",
        message:
          "This field will be deleted permanently. Please confirm to continue deleting this field.",
      });
      // return
      if (promise) {
        let url = `/fields/${field.id}`;
        try {
          let result = await axios.delete(url);
          this.$toast.success(result.data.message);
          this.$router.push({ path: "/field-table" });
        } catch (error) {
          this.$toast.error(error.response.data.detail);
        }
      }
      this.$refs["confirm-popup"].close();
    },
  },
};
</script>

<style lang="scss" scoped>
// input:disabled {
//   opacity: 0.7 !important;
//   background-color: rgb(224, 224, 224) !important;
// }
.options__list {
  min-height: 100px;
}
textarea {
  resize: none;
}
::v-deep {
  .multiselect__tags {
    min-height: 0px !important;
  }
  .multiselect__content-wrapper {
    position: relative !important;
  }
  .multiselect__option {
    min-height: 0 !important;
  }
  .form{
    min-height: 100%;
  }
}
</style>